import React, { useState, useEffect } from "react";

function Photos({ property }) {
  const [mainImage, setMainImage] = useState(property.photos[0]);
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = property.photos

  const changeMainImage = (newImage, index) => {
    setMainImage(newImage);
    setCurrentImageIndex(index);
  };

  const nextImage = () => {
    let newIndex = (currentImageIndex + 1) % images.length;
    changeMainImage(images[newIndex], newIndex);
  };

  const prevImage = () => {
    let newIndex = (currentImageIndex - 1 + images.length) % images.length;
    changeMainImage(images[newIndex], newIndex);
  };

  const closeModal = () => setShowModal(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowRight") nextImage();
      if (e.key === "ArrowLeft") prevImage();
      if (e.key === "Escape") setShowModal(false);
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentImageIndex]);

  if (images.length === 0) {
    return null;
  }

  return (
    <div>
      <div
        className="w-full rounded-lg overflow-hidden h-[70vh] bg-slate-900 bg-opacity-20 flex justify-center items-center"
        onClick={() => setShowModal(true)}
      >
        <img src={mainImage} className="max-w-full max-h-full" alt="Main" />
      </div>
      <div className="w-full whitespace-nowrap" style={{ overflowX: "auto"}}>
      <div className={`block whitespace-nowrap mt-4 gap-4 justify-between w-[${250 * images.length}px] overflow-auto`}>
        {images.map((image, index) => (
          <div
            key={index}
            style={{ background: `url(${image})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}
            className="mx-4 inline-block w-[250px] h-[250px] rounded-lg cursor-pointer"
            alt={`Thumbnail ${index}`}
            onClick={() => changeMainImage(image, index)}
          />
        ))}
      </div>
      </div>

      {showModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 "
          onClick={closeModal}
        >
          <button
            className="absolute top-1/2 left-0 p-4 text-white z-10" // Added z-10 to bring above background
            onClick={(e) => {
              prevImage();
              e.stopPropagation();
            }} // Added stopPropagation
          >
            {"<"}
          </button>
          <div className="relative">
            <img src={mainImage} className="w-full h-auto" alt="Modal Main" />
            <button
              className="absolute top-0 right-0 p-4 text-white"
              onClick={() => setShowModal(false)}
            >
              X
            </button>
          </div>
          <button
            className="absolute top-1/2 right-0 p-4 text-white z-10" // Added z-10 to bring above background
            onClick={(e) => {
              nextImage();
              e.stopPropagation();
            }} // Added stopPropagation
          >
            {">"}
          </button>
          <div className="absolute bottom-0 max-w-full overflow-x-auto flex justify-center gap-8 p-4">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                className="w-1/12 rounded-lg cursor-pointer"
                alt={`Thumbnail ${index}`}
                onClick={() => changeMainImage(image, index)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Photos;
