import React, { useEffect, useState } from "react";
import ExtendedSearch from "./ExtendedSearch";
import AppSelect from "./AppSelect";
import { districtOptions, propertyType } from "../../res/options";
import Input from "./Input";
import { useSearchParams } from "react-router-dom";

function SearchBar({ onChangeFilters, onSubmit }) {
  const [showExtended, setShowExtended] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({});
  const [ready, setReady] = useState(false);

  const onChange = (name, value) => {
    if (ready) {
      setSearchParams({ ...filters, [name]: value });
      setFilters({ ...filters, [name]: value });
    }
  };

  useEffect(() => {
    const filters = {};
    for (const entry of searchParams.entries()) {
      filters[entry[0]] = decodeURIComponent(entry[1]);
    }
    setFilters({ ...filters });
    setReady(true);
  }, [searchParams]);

  useEffect(() => {
    onChangeFilters(filters);
  }, [filters]);

  if (!ready) {
    return false;
  }

  return (
    <div>
      <div className="flex gap-2">
        <button className="text-white orange-gradient px-6 py-1 rounded-lg">
          Eladó
        </button>
        <button className="bg-white px-6 py-1 rounded-lg">Kiadó</button>
      </div>
      <div className="bg-white rounded-2xl grid grid-cols-5 gap-4 p-4 relative z-40 mt-4 card-shadow">
        <div className="flex flex-col gap-2">
          <h3 className="font-bold">Típus</h3>
          <div className="rounded-2xl w-full">
            <AppSelect
              options={propertyType}
              placeholder="Tipus"
              onChange={({ value }) => {
                onChange("propertyType", value);
              }}
              name="propertyType"
              prefill={filters}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="font-bold">Hol keresel?</h3>
          <AppSelect
            options={districtOptions}
            placeholder={"Hol keresel"}
            onChange={({ value }) => {
              onChange("district", value);
            }}
            name="district"
            prefill={filters}
          />
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="font-bold">Alapterület</h3>
          <div className="mt-2 grid grid-cols-2 gap-2">
            <Input
              variant={"white"}
              addon={
                <span>
                  m<sup>2</sup>
                </span>
              }
              placeholder={"Min"}
              addonClass={"!-ml-9 !mt-3 !p-0 !h-4 !w-6"}
              type="number"
              onChange={(e) => {
                onChange("minArea", e.target.value);
              }}
              value={filters.minArea}
            />
            <Input
              variant={"white"}
              addon={
                <span>
                  m<sup>2</sup>
                </span>
              }
              placeholder={"Max"}
              addonClass={"!-ml-9 !mt-3 !p-0 !h-4 !w-6"}
              type="number"
              onChange={(e) => {
                onChange("maxArea", e.target.value);
              }}
              value={filters.maxArea}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="font-bold">Ár (millió)</h3>
          <div className="mt-2 grid grid-cols-2 gap-2">
            <Input
              variant={"white"}
              addon={<span>Ft</span>}
              placeholder={"Min"}
              addonClass={"!-ml-9 !mt-3 !p-0 !h-4 !w-6"}
              type="number"
              onChange={(e) => {
                onChange("minPrice", e.target.value);
              }}
              value={filters.minPrice}
            />
            <Input
              variant={"white"}
              addon={<span>Ft</span>}
              placeholder={"Max"}
              addonClass={"!-ml-9 !mt-3 !p-0 !h-4 !w-6"}
              type="number"
              onChange={(e) => {
                onChange("maxArea", e.target.value);
              }}
              value={filters.maxPrice}
            />
          </div>
        </div>
        <div className="mt-9 flex flex-col gap-3">
          <button className="orange-button" onClick={onSubmit}>
            Keresés
          </button>
          <p
            className="text-[#EC6608] text-sm ml-1.5 cursor-pointer"
            onClick={() => setShowExtended(!showExtended)}
          >
            Részletes keresés
          </p>
        </div>
        {showExtended && (
          <ExtendedSearch
            onChange={onChange}
            filters={filters}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </div>
  );
}

export default SearchBar;
