import React from "react";
import newsImage1 from "../pictures/news-1.png";
import newsImage2 from "../pictures/news-2.png";
import newsImage3 from "../pictures/news-3.png";
import NewsCard from "./Elements/NewsCard";

const newsData = [
    {
        image: newsImage1,
        heading: "Családi házra hajtanak a fiatalok",
        text: "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Proin congue pretium erat, eget ultrices urna ultrices"
    },
    {
        image: newsImage2,
        heading: "Vége az extrém drágulásnak, alig növekedtek a négyzetméterárak",
        text: "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Proin congue pretium erat, eget ultrices urna ultrices"
    },
    {
        image: newsImage3,
        heading: "Családi házra hajtanak a fiatalok",
        text: "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Proin congue pretium erat, eget ultrices urna ultrices"
    },
]

function News () {
    return (
        <div className='w-10/12 mx-auto py-8 my-4'>
            <p className="section-heading">
                <span className="section-heading-blue">Hírek</span>, elemzések
            </p>
            <div className="flex gap-4 mt-8 justify-items-stretch">
                {
                    newsData.map(news => <NewsCard news={news} />)
                }
            </div>
        </div>
    )
}

export default News;
