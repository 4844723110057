import React, { useContext, useEffect, useState } from "react";
import logo from "../pictures/logo.png";
import Cookies from "js-cookie";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../App";
import { profilePhotoPlaceholder } from "./Profile";
import dropdownIcon from "../pictures/app/dropdown.svg";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const [show, setShow] = useState(false);

  const { user } = useContext(AppContext);

  useEffect(() => {
    const token = Cookies.get("token");
    setIsLoggedIn(!!token);
    const hide = () => setShow(false);
    window.addEventListener('click', hide);
    return () => window.removeEventListener('click', hide);
  }, []);

  const logout = () => {
    Cookies.remove("token");
    window.location.reload();
  };

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="flex w-10/12 p-6 mx-auto items-center gap-10 relative z-40 bg-[rgb(247,247,247)]">
      <div className="w-[35%]">
        <a href="/">
          <img src={logo} alt="logo" className="h-14" />
        </a>
      </div>

      <div className="flex justify-between w-[70%] gap-6 text-lightgrey font-bold">
        <ul className="flex items-center gap-6">
          <li className={isActive("/") ? "text-[#EC6608]" : ""}>
            <Link to="/">Főoldal</Link>
          </li>
          <li
            className={`mx-2 ${isActive("/about-us") ? "text-[#EC6608]" : ""}`}
          >
            <Link to="/about-us">Rólunk</Link>
          </li>
          <li className={`mx-2 ${isActive("/news") ? "text-[#EC6608]" : ""}`}>
            <Link to="/news">Hírek</Link>
          </li>
          <li className="mx-2">Kapcsolat</li>
          <li className={`mx-2 ${isActive("/faqs") ? "text-[#EC6608]" : ""}`}>
            <Link to="/faqs">GYIK</Link>
          </li>
        </ul>
        {isLoggedIn ? (
          <div className="flex gap-2 cursor-pointer" onClick={(e) => {
            e.stopPropagation();
            setShow(!show);
          }}>
            <div>
              <div className="flex gap-2 mt-1">
                <div 
                  className="h-[40px] w-[40px] rounded-full"
                  style={{
                    background: `url(${ user?.photo || profilePhotoPlaceholder })`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                />
                <div className="flex justify-center items-center text-blue select-none">
                  {user?.lastName}
                </div>
                <div>
                  <img src={dropdownIcon} className={"mt-3 h-4 w-4 flex justify-center items-center " + (show ? "" : "rotate-180")} />
                </div>
              </div>
              <div className={"absolute bg-white shadow mt-2 rounded-lg w-36 overflow-hidden " + (show ? "" : "hidden")}>
                <div className="w-full p-2 hover:bg-gray-50">
                  {user?.credit} hitel
                </div>
                <Link to="/profile">
                <button className="w-full p-2 hover:bg-gray-50 text-left">
                Profil Adatok
                </button>
                </Link>
                <button className="w-full p-2 hover:bg-gray-50 text-left" onClick={logout}>
                  Kijelentkezés
                </button>
              </div>
            </div>
            <Link to="/post-ad">
              <button className="orange-button">Hirdetésfeladás</button>
            </Link>
          </div>
        ) : (
          <div className="flex gap-6">
            <Link to="/login" className="blue-button">
              Bejelentkezés
            </Link>
            <Link to="/post-ad">
              <button className="orange-button">Hirdetésfeladás</button>
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
