import React, { useState } from "react";

const tableFields = [
  { label: "Építés éve", value: "builtYear" },
  { label: "Építési mód:", value: "constructionMethod" },
  { label: "Emelet", value: "floor" },
  { label: "Fűtés", value: "heating" },
  { label: "Tájolás", value: "orientation" },
  { label: "Ingatlan Típusa", value: "propertyType" },
  { label: "Belmagasság", value: "ceilingHeight" },
  { label: "Kilátás", value: "view" },
  { label: "Erkély", value: "balcony" },
];

function About({ property }) {
  const [activeTab, setActiveTab] = useState("Leírás");

  const renderContent = () => {
    switch (activeTab) {
      case "Leírás":
        return <div dangerouslySetInnerHTML={{ __html: property?.description || "" }}></div>
      case "Tulajdonágok":
        return (
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border px-4 py-2">Tulajdonság</th>
                <th className="border px-4 py-2">Érték</th>
              </tr>
            </thead>
            <tbody>
              {tableFields
                .map((field) => ({
                  attribute: field.label,
                  value: property[field.value],
                }))
                .map((item, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{item.attribute}</td>
                    <td className="border px-4 py-2">{item.value}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        );
      default:
        return "Nincs adat...";
    }
  };

  return (
    <div className="light-card-shadow p-6 rounded-2xl bg-white mt-8">
      <p className="card-heading">Az ingatlanról</p>
      <div className="mt-4 flex gap-4">
        {["Leírás", "Tulajdonágok"].map((tabName) => (
          <span
            key={tabName}
            className={`py-2 px-4 rounded-full font-bold ${
              activeTab === tabName
                ? "orange-gradient text-white"
                : "bg-gray-200 text-grey-600"
            }`}
            onClick={() => setActiveTab(tabName)}
          >
            {tabName}
          </span>
        ))}
      </div>
      <div className="mt-8 mb-16 text-lightgrey text-sm">{renderContent()}</div>
    </div>
  );
}

export default About;
