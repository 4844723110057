import React from "react";
import whiteLogo from "../icons/white-logo.svg";

function AboutFooter () {

    return (
        <div className='w-screen mt-4 grid grid-cols-2 gap-6 overflow-hidden relative min-h-[538px] about-footer'>
            <div className="about-footer-circle">
                <img 
                    src={whiteLogo}
                    className="h-48 w-48 mt-72 ml-48"
                />
            </div>
            <div className="h-full bg-gradient-to-r from-black via-transparent to-transparent">
                <div className="mt-[256px] ml-[128px]">
                    <p className="section-heading text-white">
                        <span className="text-blue">Mi segítünk,</span> hogy  gyorsan, <br/>
                        és könnyedén adj el vagy <br/>
                        keress ingatlant 
                    </p>
                    <div className="mt-4">
                        <button className="orange-button">Hirdetésfeladás</button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AboutFooter;
