import React from "react";
import { districtOptions, settlementOptions } from "../../res/options";
import thousandSeparator from "../../utils/thousandSeparator";
import moment from "moment";
import viewsIcon from "../../pictures/app/view.svg";
import timeIcon from "../../pictures/app/time.svg";
import { Link } from "react-router-dom";

function MyPropertyCard({ property }) {
  const district = districtOptions.find(
    (option) => option.value === property.district
  );
  const settlement = settlementOptions.find(
    (option) => option.value === property.settlement
  );

  return (
    <div className="rounded-2xl shadow-lg grid grid-cols-3 bg-white my-4 overflow-hidden">
      <div className="overflow-hidden h-84">
        <img src={property.photos[0]} className="min-h-full min-w-full" />
      </div>
      <div className="p-8 px-16 col-span-2">
        <p className="font-bold">{property.title}</p>
        <p className="font-bold text-gray-400">
          {district?.label} {settlement?.label}
        </p>
        <p className="my-6 text-orange text-2xl font-bold">
          {thousandSeparator(property.price.toFixed(0) + "", " ")} Ft
        </p>
        <div className="font-bold">
          <p className="text-blue my-1 flex gap-3">
            <img src={viewsIcon} className="h-4 w-4 mt-1" />
            Megtekintve:{" "}
            <span className="text-gray-400 ml-1">{property.views || 0}</span>
          </p>
          <p className="text-blue my-1 flex gap-3">
            <img src={timeIcon} className="h-4 w-4 mt-1" /> Feladva:
            <span className="text-gray-400 ml-1">
              {moment(property.submittedOn).format("YYYY-MM-DD")}
            </span>
          </p>
        </div>
        <div className="my-4">
          <Link to={`/edit-property/${property?.id}`}>
            <button className="orange-button">Szerkeszt</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MyPropertyCard;
