import React from "react";
import ExpandableCard from "../Elements/ExpandableCard";
import icon from "../../pictures/app/ad-details.svg";
import AppSelect from "../Elements/AppSelect";
import Input from "../Elements/Input";
import Switch from "../Elements/Switch";
import Select from "../Elements/Select";
import {
  balconyOptions,
  builtYear,
  ceilingHeightOptions,
  constructionMethod,
  floorOptions,
  heatingOptions,
  orientationOptions,
  status,
  viewOptions,
} from "../../res/options";

function MoreDetails({ prefill }) {
  return (
    <ExpandableCard
      Component={({ isExpanded, setIsExpanded }) => {
        return (
          <div className="w-full my-8 p-6">
            <div className="w-full grid grid-cols-2">
              <div className="flex gap-4 items-center">
                <img src={icon} className="h-6 w-6" />
                <span className="text-xl font-bold mt-0">Egyéb adatok</span>
              </div>
              <div>
                {!isExpanded && (
                  <span className="float-right">
                    <button
                      type="button"
                      className="orange-button"
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      Beállítás
                    </button>
                  </span>
                )}
              </div>
            </div>
            <div
              className="py-6"
              style={{ display: isExpanded ? "block" : "none" }}
            >
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <p className="font-bold">Építési mód:</p>
                  <AppSelect
                    options={constructionMethod}
                    name="constructionMethod"
                    prefill={prefill}
                  />
                </div>
                <div>
                  <p className="font-bold">Állapot</p>
                  <AppSelect
                    options={status}
                    name="propertyStatus"
                    prefill={prefill}
                  />
                </div>
                <div>
                  <p className="font-bold">Építés éve</p>
                  <AppSelect
                    options={builtYear}
                    name="builtYear"
                    prefill={prefill}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4 my-4">
                <div>
                  <p className="font-bold">Kilátás</p>
                  <AppSelect
                    options={viewOptions}
                    name="view"
                    prefill={prefill}
                  />
                </div>
                <div>
                  <p className="font-bold">Tájolás</p>
                  <AppSelect
                    options={orientationOptions}
                    name="orientation"
                    prefill={prefill}
                  />
                </div>
                <div>
                  <p className="font-bold">Belmagasság</p>
                  <AppSelect
                    options={ceilingHeightOptions}
                    name="ceilingHeight"
                    prefill={prefill}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4 my-4">
                <div>
                  <p className="font-bold">Emelet</p>
                  <AppSelect
                    options={floorOptions}
                    name="floor"
                    prefill={prefill}
                  />
                </div>
                <div>
                  <p className="font-bold">Erkély</p>
                  <AppSelect
                    options={balconyOptions}
                    name="balcony"
                    prefill={prefill}
                  />
                </div>
                <div>
                  <p className="font-bold">Fűtés</p>
                  <AppSelect
                    options={heatingOptions}
                    name="heating"
                    prefill={prefill}
                  />
                </div>
              </div>
              <div className="my-8 h-1">
                <span className="float-right">
                  <button
                    type="button"
                    className="blue-button"
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    Mentés
                  </button>
                </span>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

export default MoreDetails;
