import React from "react";
import AppSelect from "./AppSelect";
import { balconyOptions, builtYear, ceilingHeightOptions, constructionMethod, floorOptions, heatingOptions, orientationOptions, status, viewOptions } from "../../res/options";

function ExtendedSearch ({ onChange, filters, onSubmit }) {

    return (
        <div className="grid grid-cols-3 gap-8 p-4 bg-white w-full col-span-5">
            <div className="w-full mt-2">
                <p className="text-lg text-gray-900 font-bold my-1">Építési mód:</p>
                <AppSelect 
                    placeholder={"Mindegy"} 
                    options={constructionMethod}
                    onChange={({ value }) => {
                        onChange("constructionMethod", value);
                    }}
                    name="constructionMethod"
                    prefill={filters}
                />
            </div>
            <div className="w-full mt-2">
                <p className="text-lg text-gray-900 font-bold my-1">Status</p>
                <AppSelect 
                    placeholder={"Mindegy"}
                    options={status}
                    onChange={({ value }) => {
                        onChange("propertyStatus", value);
                    }}
                    name="propertyStatus"
                    prefill={filters}
                />
            </div>
            <div className="w-full mt-2">
                <p className="text-lg text-gray-900 font-bold my-1">Építés éve</p>
                <AppSelect 
                    placeholder={"Mindegy"}
                    options={builtYear}
                    onChange={({ value }) => {
                        onChange("builtYear", value);
                    }}
                    name="builtYear"
                    prefill={filters}
                />
            </div>

            <div className="w-full mt-2">
                <p className="text-lg text-gray-900 font-bold my-1">Kilátás</p>
                <AppSelect 
                    placeholder={"Mindegy"}
                    options={viewOptions}
                    onChange={({ value }) => {
                        onChange("view", value);
                    }}
                    name="view"
                    prefill={filters}
                />
            </div>
            <div className="w-full mt-2">
                <p className="text-lg text-gray-900 font-bold my-1">Tájolás</p>
                <AppSelect 
                    placeholder={"Mindegy"}
                    options={orientationOptions}
                    onChange={({ value }) => {
                        onChange("orientation", value);
                    }}
                    name="orientation"
                    prefill={filters}
                />
            </div>
            <div className="w-full mt-2">
                <p className="text-lg text-gray-900 font-bold my-1">Belmagasság</p>
                <AppSelect 
                    placeholder={"Mindegy"}
                    options={ceilingHeightOptions}
                    onChange={({ value }) => {
                        onChange("ceilingHeight", value);
                    }}
                    name="ceilingHeight"
                    prefill={filters}
                />
            </div>

            <div className="w-full mt-2 gap-2">
                <div>
                    <p className="text-lg text-gray-900 font-bold my-1">Emelet</p>
                    <AppSelect 
                        placeholder={"Mindegy"}
                        options={floorOptions}
                        onChange={({ value }) => {
                            onChange("floor", value);
                        }}
                        name="floor"
                        prefill={filters}
                    />
                </div>
            </div>
            <div className="w-full mt-2">
                <p className="text-lg text-gray-900 font-bold my-1">Erkély</p>
                <AppSelect 
                    placeholder={"Mindegy"}
                    options={balconyOptions}
                    onChange={({ value }) => {
                        onChange("balcony", value);
                    }}
                    name="balcony"
                    prefill={filters}
                />
            </div>
            <div className="w-full mt-2">
                <p className="text-lg text-gray-900 font-bold my-1">Fűtés</p>
                <AppSelect 
                    placeholder={"Mindegy"}
                    options={heatingOptions}
                    onChange={({ value }) => {
                        onChange("heating", value);
                    }}
                    name="heating"
                    prefill={filters}
                />
            </div>

            <div className="col-span-3">
                <div className="w-1/3 mt-3">
                    <span className="font-bold text-lg text-gray-900">Kiemelt megbízás</span>
                    <span className="float-right border-2 rounded w-5 h-5" />
                </div>
                <div className="w-1/3 mt-3">
                    <span className="font-bold text-lg text-gray-900">Árgaranciával</span>
                    <span className="float-right border-2 rounded w-5 h-5" />
                </div>
                <div className="w-1/3 mt-3">
                    <span className="font-bold text-lg text-gray-900">Energiatanúsítvány</span>
                    <span className="float-right border-2 rounded w-5 h-5" />
                </div>
                <div className="w-1/3 mt-3">
                    <span className="font-bold text-lg text-gray-900">Banki ingatlan</span>
                    <span className="float-right border-2 rounded w-5 h-5" />
                </div>
            </div>

            <div/>
                
            <div className="col-span-2">
                <span className="float-right mx-2">
                    <button className="blue-button" onClick={onSubmit}>Keresés</button>
                </span>
                <span className="float-right">
                    <button className="blue-button border-2" style={{ background: "white", color: "#333" }}>Bezárás </button>
                </span>
            </div>
        </div>
    )

}

export default ExtendedSearch;
