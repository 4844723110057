import { Routes, Route } from "react-router-dom";

import "./App.css";
import AboutFooter from "./components/AboutFooter";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import News from "./components/News";
import PopularProperties from "./components/PopularProperties";
import WhyUs from "./components/WhyUs";
import WhyUs2 from "./components/WhyUs2";
import Property from "./pages/Property";
import Search from "./pages/Search";
import { Toaster } from "react-hot-toast";

import Login from "./components/Auth/Login";
import SignUp from "./components/Auth/Signup";
import EmailConfirmation from "./components/Auth/EmailConfirmation";
import AboutUs from "./components/AboutUs";
import NewsPage from "./components/NewsPage";
import FAQs from "./components/FAQs";
import ResetPassword from "./components/Auth/ResetPassword";
import PostAd from "./components/PostAd";
import Profile from "./components/Profile";
import { createContext, useEffect, useState } from "react";
import { request } from "./utils/request";
import ManageAds from "./components/ManageAds";
import EditAd from "./pages/EditAd";
import BiddingDashboard from "./components/BiddingDashboard";
import Maintainence from "./components/Maintainence";
import config from "./config";

export const AppContext = createContext();

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <PopularProperties />
      <WhyUs />
      <WhyUs2 />
      <News />
      <AboutFooter />
      <Footer />
    </>
  );
};

function App() {

  const [data, setData] = useState({});

  const loadData = async () => {
    const user = await request("/api/user/me");
    setData({
      ...data,
      user: user.data,
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <AppContext.Provider value={{ ...data, setData }}>
    <Routes>
      <Route path="/" exact element={config.mode === "MAINTAINENCE" ? <Maintainence /> : <Home />} />
      <Route path="/property/:id" element={<Property />} />
      <Route path="/search" element={<Search />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/news" element={<NewsPage />} />
      <Route path="/faqs" element={<FAQs />} />
      <Route path="/confirm-email/:token" element={<EmailConfirmation />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/post-ad" element={<PostAd />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/manage-ads" element={<ManageAds />} />
      <Route path="/edit-property/:id" element={<EditAd />} />
      <Route path="/bidding" element={<BiddingDashboard />} />
    </Routes>
    <Toaster />
    </AppContext.Provider>
  );
}

export default App;
