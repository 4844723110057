import React from "react";
import map from "../../pictures/map.png";
import pin from "../../icons/ping.svg";

function Map () {

    return (
        <div className="p-6 bg-white rounded-2xl light-card-shadow">
            <p className="card-heading">Ingatlan elhelyezkedése</p>
            <div className="mt-4 relative">
                <img 
                    src={map}
                    className="min-w-[100%]"
                />
                <div className="w-64 h-64 bg-[#00000011] absolute top-[10%] left-[20%] rounded-full flex justify-center items-center">
                    <img 
                        src={pin}
                        className="h-16 w-16"
                    />
                </div>
            </div>
        </div>
    )

}

export default Map;
