export const status = [
  { value: "aktiv", label: "Aktív" },
  { value: "inaktiv", label: "Inaktív" },
  { value: "ertekesitve", label: "Értékesítve" },
  { value: "lezarva", label: "Lezárva" },
];

export const adType = [
  { value: "elado", label: "Eladó" },
  { value: "kiado", label: "Kiadó" },
];

export const propertyType = [
  { value: "lakas", label: "Lakás" },
  { value: "haz", label: "Ház" },
  { value: "telek", label: "Telek" },
  { value: "iroda", label: "Iroda" },
  { value: "uzlethelyiseg", label: "Üzlethelyiség" },
  { value: "ipari-ingatlan", label: "Ipari ingatlan" },
  { value: "garazs", label: "Garázs" },
  { value: "tarolo", label: "Tároló" },
  { value: "vendeglatohely", label: "Vendéglátóhely" },
  { value: "fejlesztesi-terulet", label: "Fejlesztési terület" },
  { value: "irodahaz", label: "Irodaház" },
  { value: "szallashely", label: "Szálláshely" },
  { value: "mezogazdasagi-terulet", label: "Mezőgazdasági terület" },
];

export const constructionMethod = [
  { value: "Tégla", label: "Tégla" },
  { value: "Panel", label: "Panel" },
];

export const btype = [
  { value: "csaladi-haz", label: "Családi ház", type: "lakas" },
  { value: "ikerhaz", label: "Ikerház", type: "lakas" },
  { value: "sorhaz", label: "Sorház", type: "lakas" },
  { value: "hazresz", label: "Házrész", type: "lakas" },
  { value: "tanya", label: "Tanya", type: "lakas" },
  { value: "kuria", label: "Kúria", type: "lakas" },
  { value: "kastely", label: "Kastély", type: "lakas" },
  { value: "utcarol-nyilo", label: "Utcáról nyíló", type: "iroda" },
  { value: "hazban", label: "Házban", type: "iroda" },
  { value: "lakasban", label: "Lakásban", type: "iroda" },
  { value: "a-irodaepulet", label: "A irodaépület", type: "iroda" },
  { value: "a+-irodaepulet", label: "A+ irodaépület", type: "iroda" },
  { value: "b-irodaepulet", label: "B irodaépület", type: "iroda" },
  { value: "c-irodaepulet", label: "C irodaépület", type: "iroda" },
  { value: "bevasarlokozpontban", label: "Bevásárlóközpontban", type: "iroda" },
  { value: "telken", label: "Telken", type: "iroda" },
  { value: "utcarol-nyilo", label: "Utcáról nyíló", type: "uzlethelyiseg" },
  { value: "hazban", label: "Házban", type: "uzlethelyiseg" },
  { value: "lakasban", label: "Lakásban", type: "uzlethelyiseg" },
  { value: "a-irodaepulet", label: "A irodaépület", type: "uzlethelyiseg" },
  { value: "a+-irodaepulet", label: "A+ irodaépület", type: "uzlethelyiseg" },
  { value: "b-irodaepulet", label: "B irodaépület", type: "uzlethelyiseg" },
  { value: "c-irodaepulet", label: "C irodaépület", type: "uzlethelyiseg" },
  {
    value: "bevasarlokozpontban",
    label: "Bevásárlóközpontban",
    type: "uzlethelyiseg",
  },
  { value: "telken", label: "Telken", type: "uzlethelyiseg" },
  { value: "muhely", label: "Műhely", type: "ipari-ingatlan" },
  { value: "raktar", label: "Raktár", type: "ipari-ingatlan" },
  { value: "gyar", label: "Gyár", type: "ipari-ingatlan" },
  { value: "uzem", label: "Üzem", type: "ipari-ingatlan" },
  { value: "aruhaz", label: "Áruház", type: "ipari-ingatlan" },
  { value: "benzinkut", label: "Benzinkút", type: "ipari-ingatlan" },
  {
    value: "sportletesitmeny",
    label: "Sportlétesítmény",
    type: "ipari-ingatlan",
  },
  { value: "egyeb", label: "Egyéb", type: "ipari-ingatlan" },
  { value: "onallo", label: "Önálló", type: "tarolo" },
  { value: "kozos", label: "Közös", type: "tarolo" },
  { value: "bar", label: "Bár", type: "vendeglatohely" },
  { value: "borospince", label: "Borospince", type: "vendeglatohely" },
  { value: "bufe", label: "Büfé", type: "vendeglatohely" },
  { value: "cukraszda", label: "Cukrászda", type: "vendeglatohely" },
  { value: "csarda", label: "Csárda", type: "vendeglatohely" },
  { value: "etelbar", label: "Ételbár", type: "vendeglatohely" },
  { value: "etterem", label: "Étterem", type: "vendeglatohely" },
  { value: "fogado", label: "Fogadó", type: "vendeglatohely" },
  { value: "gyorsetterem", label: "Gyorsétterem", type: "vendeglatohely" },
  { value: "internet-cafe", label: "Internet cafe", type: "vendeglatohely" },
  { value: "kavezo", label: "Kávézó", type: "vendeglatohely" },
  { value: "kocsma", label: "Kocsma", type: "vendeglatohely" },
  { value: "onkiszolgalo", label: "Önkiszolgáló", type: "vendeglatohely" },
  { value: "palacsintazo", label: "Palacsintázó", type: "vendeglatohely" },
  { value: "pekseg", label: "Pékség", type: "vendeglatohely" },
  { value: "pizzeria", label: "Pizzéria", type: "vendeglatohely" },
  { value: "pub", label: "Pub", type: "vendeglatohely" },
  { value: "salatabar", label: "Salátabár", type: "vendeglatohely" },
  { value: "szendvicsbar", label: "Szendvicsbár", type: "vendeglatohely" },
  { value: "szorakozohely", label: "Szórakozóhely", type: "vendeglatohely" },
  { value: "egyeb", label: "Egyéb", type: "vendeglatohely" },
  { value: "altalanos", label: "Általános", type: "fejlesztesi-terulet" },
  { value: "lakoterulet", label: "Lakóterület", type: "fejlesztesi-terulet" },
  { value: "uduloterulet", label: "Üdülőterület", type: "fejlesztesi-terulet" },
  {
    value: "ipari-terulet",
    label: "Ipari terület",
    type: "fejlesztesi-terulet",
  },
  {
    value: "onallo-epulet",
    label: "Önálló épület",
    type: "fejlesztesi-terulet",
  },
  {
    value: "szolgaltatasi-terulet",
    label: "Szolgáltatási terület",
    type: "fejlesztesi-terulet",
  },
  { value: "epuletresz", label: "Épületrész", type: "fejlesztesi-terulet" },
  { value: "a-irodaepulet", label: "A irodaépület", type: "irodahaz" },
  { value: "a+-irodaepulet", label: "A+ irodaépület", type: "irodahaz" },
  { value: "b-irodaepulet", label: "B irodaépület", type: "irodahaz" },
  { value: "c-irodaepulet", label: "C irodaépület", type: "irodahaz" },
  { value: "bevasarlokozpont", label: "Bevásárlóközpont", type: "irodahaz" },
  { value: "szalloda", label: "Szálloda", type: "szallashely" },
  { value: "hotel", label: "Hotel", type: "szallashely" },
  { value: "hostel", label: "Hostel", type: "szallashely" },
  { value: "panzio", label: "Panzió", type: "szallashely" },
  { value: "egyeb", label: "Egyéb", type: "szallashely" },
  { value: "altalanos", label: "Általános", type: "mezogazdasagi-terulet" },
  { value: "gyumolcsos", label: "Gyümölcsös", type: "mezogazdasagi-terulet" },
  { value: "szanto", label: "Szántó", type: "mezogazdasagi-terulet" },
  { value: "szolos", label: "Szőlős", type: "mezogazdasagi-terulet" },
  { value: "erdo", label: "Erdő", type: "mezogazdasagi-terulet" },
  { value: "halasto", label: "Halastó", type: "mezogazdasagi-terulet" },
  { value: "egyeb", label: "Egyéb", type: "mezogazdasagi-terulet" },
];

export const currency = [
  { value: "huf", label: "HUF" },
  { value: "eur", label: "EUR" },
  { value: "usd", label: "USD" },
];

export const property_condition = [
  { value: "1", label: "1 - Lakhatatlan" },
  { value: "2", label: "2 - Felújítandó" },
  { value: "3", label: "3 - Lakható" },
  { value: "4", label: "4 - Jó" },
  { value: "5", label: "5 - Nagyon jó" },
  { value: "uj-ep.", label: "Új ép." },
];

export const building_condition_in = [
  { value: "1", label: "1 - Lakhatatlan" },
  { value: "2", label: "2 - Felújítandó" },
  { value: "3", label: "3 - Lakható" },
  { value: "4", label: "4 - Jó" },
  { value: "5", label: "5 - Nagyon jó" },
  { value: "uj-ep.", label: "Új ép." },
];

export const building_condition_out = [
  { value: "1", label: "1 - Lakhatatlan" },
  { value: "2", label: "2 - Felújítandó" },
  { value: "3", label: "3 - Lakható" },
  { value: "4", label: "4 - Jó" },
  { value: "5", label: "5 - Nagyon jó" },
  { value: "uj-ep.", label: "Új ép." },
];

export const komfort = [
  { value: "komfort-nelkuli", label: "Komfort nélküli" },
  { value: "felkomfortos", label: "Félkomfortos" },
  { value: "komfortos", label: "Komfortos" },
  { value: "osszkomfortos", label: "Összkomfortos" },
  { value: "duplakomfortos", label: "Duplakomfortos" },
  { value: "luxus", label: "Luxus" },
];

export const viewOptions = [
  { value: "utca", label: "Utca" },
  { value: "udvar", label: "Udvar" },
  { value: "zoldudvar-/-kert", label: "Zöldudvar / kert" },
];

export const floorOptions = [
  { value: "-5", label: "-5" },
  { value: "-4", label: "-4" },
  { value: "-3", label: "-3" },
  { value: "-2", label: "-2" },
  { value: "-1", label: "-1" },
  { value: "pinceszint", label: "Pinceszint" },
  { value: "alagsor", label: "Alagsor" },
  { value: "szuteren", label: "Szuterén" },
  { value: "felszuteren", label: "Félszuterén" },
  { value: "foldszint", label: "Földszint" },
  { value: "magasfoldszint", label: "Magasföldszint" },
  { value: "felemelet", label: "Félemelet" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "20+", label: "20+" },
];

export const ltyp = [
  { value: "lako-ovezet", label: "Lakó övezet" },
  { value: "udulo-ovezet", label: "Üdülő övezet" },
  { value: "kulterulet", label: "Külterület" },
  { value: "egyeb", label: "Egyéb" },
];

export const rend = [
  { value: "lakoingatlan", label: "Lakóingatlan" },
  { value: "nyaralo", label: "Nyaraló" },
  { value: "egyeb", label: "Egyéb" },
];

export const water = [
  { value: "van", label: "Van" },
  { value: "nincs-adat", label: "Nincs adat" },
  { value: "telken-belul", label: "Telken belül" },
  { value: "utcaban", label: "Utcában" },
  { value: "nincs", label: "Nincs" },
];

export const gas = [
  { value: "van", label: "Van" },
  { value: "nincs-adat", label: "Nincs adat" },
  { value: "telken-belul", label: "Telken belül" },
  { value: "utcaban", label: "Utcában" },
  { value: "nincs", label: "Nincs" },
];

export const sewage = [
  { value: "van", label: "Van" },
  { value: "nincs-adat", label: "Nincs adat" },
  { value: "telken-belul", label: "Telken belül" },
  { value: "utcaban", label: "Utcában" },
  { value: "nincs", label: "Nincs" },
];

export const electr = [
  { value: "van", label: "Van" },
  { value: "nincs-adat", label: "Nincs adat" },
  { value: "telken-belul", label: "Telken belül" },
  { value: "utcaban", label: "Utcában" },
  { value: "nincs", label: "Nincs" },
];

export const builds = [
  { value: "panel", label: "Panel" },
  { value: "tegla", label: "Tégla" },
  { value: "konnyuszerkezetes", label: "Könnyűszerkezetes" },
  { value: "ytong", label: "Ytong" },
  { value: "fa", label: "Fa" },
  { value: "csusztatott-zsalu", label: "Csúsztatott zsalu" },
  { value: "valyog", label: "Vályog" },
  { value: "vegyes-falazat", label: "Vegyes falazat" },
  { value: "vert-falazat", label: "Vert falazat" },
  { value: "egyeb", label: "Egyéb" },
];

export const light = [
  { value: "gyenge", label: "Gyenge" },
  { value: "kozepes", label: "Közepes" },
  { value: "jo", label: "Jó" },
  { value: "napfenyes", label: "Napfényes" },
];

export const heatingOptions = [
  { value: "gaz-(cirko)", label: "Gáz (cirkó)" },
  { value: "gaz-(konvektor)", label: "Gáz (konvektor)" },
  { value: "gaz-(hera)", label: "Gáz (héra)" },
  { value: "gaz+napkollektor", label: "Gáz + napkollektor" },
  { value: "gazkazan", label: "Gázkazán" },
  { value: "elektromos", label: "Elektromos" },
  { value: "egyeb-kazan", label: "Egyéb kazán" },
  { value: "egyeb", label: "Egyéb" },
  { value: "tavfutes", label: "Távfűtés" },
  { value: "hazkozponti", label: "Házközponti" },
  {
    value: "hazkozponti-egyedi-meressel",
    label: "Házközponti egyedi méréssel",
  },
  { value: "geotermikus", label: "Geotermikus" },
  { value: "tavfutes-egyedi-merovel", label: "Távfűtés egyedi mérővel" },
  { value: "hoszivattyus", label: "Hőszivattyús" },
];

export const lift = [
  { value: "nincs", label: "Nincs" },
  { value: "kulcsos", label: "Kulcsos" },
  { value: "hivo", label: "Hívó" },
];

export const orientationOptions = [
  { value: "eny", label: "ÉNY" },
  { value: "ek", label: "ÉK" },
  { value: "k", label: "K" },
  { value: "d", label: "D" },
  { value: "dk", label: "DK" },
  { value: "dny", label: "DNY" },
  { value: "ny", label: "NY" },
  { value: "e", label: "É" },
];

export const stair = [
  { value: "zart-lepcsohaz", label: "Zárt lépcsőház" },
  { value: "korfolyoso", label: "Körfolyosó" },
];

export const parktype = [
  { value: "onallo-garazs", label: "Önálló garázs" },
  { value: "teremgarazs", label: "Teremgarázs" },
  { value: "udvari-beallo", label: "Udvari beálló" },
  { value: "utcaban", label: "Utcában" },
];

export const extras = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "10", label: "10" },
  { value: "10", label: "10" },
  { value: "10", label: "10" },
  { value: "10", label: "10" },
  { value: "10", label: "10" },
  { value: "10", label: "10" },
  { value: "10", label: "10" },
  { value: "10", label: "10" },
  { value: "10", label: "10" },
  { value: "10", label: "10" },
  { value: "10", label: "10" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "53", label: "53" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
];

export const builtYear = [
  { value: 2023, label: "2023" },
  { value: 2022, label: "2022" },
  { value: 2021, label: "2021" },
  { value: 2020, label: "2020" },
  { value: 2019, label: "2019" },
  { value: 2018, label: "2018" },
  { value: 2017, label: "2017" },
  { value: 2016, label: "2016" },
];

export const ceilingHeightOptions = [
  { value: "2 méter alatti", label: "2 méter alatti" },
  { value: "2 méter feletti", label: "2 méter feletti" },
];

export const settlementOptions = [
  { value: "Budapest", label: "Budapest" },
  { value: "Érd", label: "Érd" },
  { value: "Diósd", label: "Diósd" },
];

export const districtOptions = [
  { value: "I. kerület", label: "I. kerület" },
  { value: "II. kerület", label: "II. kerület" },
  { value: "III. kerület", label: "III. kerület" },
];

export const balconyOptions = [
  { value: "Erkély", label: "Erkély" },
  { value: "Terasz", label: "Terasz" },
];
