import React from "react";
import pinIcon from "../../icons/pin.svg";
import roomIcon from "../../icons/room.svg";
import arrows from "../../icons/two-head-arrow.svg";
import { Link } from "react-router-dom";

function PropertyCard ({ property }) {

    return (
        <Link to={`/property/${property.id}`}>
        <div className="bg-white rounded-2xl card-shadow relative overflow-hidden">
            <div className="overflow-hidden round-2xl relative">
                {
                    property.isPromoted &&
                    <div className="absolute z-40 orange-button flex justify-center items-center right-2 top-2 p-2 !h-8 !w-28 shadow-lg">
                        Promoted
                    </div>
                }
                <div 
                    className="w-full h-72"
                    style={{
                        background: `url(${property.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}
                />
            </div>
            <div className="mt-2 p-4">
                <p className="card-heading">{property.title}</p>
            </div>
            <div className="w-full grid grid-cols-2 p-4 font-bold" style={{ gridTemplateColumns: "calc(100% - 100px) 100px" }}>
                <div>
                    <div>
                        <p className="flex gap-2">
                            <img src={pinIcon} />
                            <span className="text-lightgrey text-sm">{property?.district}</span>
                        </p>
                    </div>
                    <div className="grid grid-cols-2 gap-2 mt-2">
                        <div className="flex gap-2">
                            <img src={roomIcon} />
                            <span className="text-lightgrey text-sm">{property.rooms} szoba</span>
                        </div>
                        <div className="flex gap-2">
                            <img src={arrows} />
                            <span className="text-lightgrey text-sm">{property.area}m<sup>2</sup></span>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <button className="orange-button float-right">Részletek</button>
                </div>
            </div>
        </div>
        </Link>
    )

}

export default PropertyCard;
