import React from "react";
import roomIcon from "../../icons/room.svg";
import arrows from "../../icons/two-head-arrow.svg";
import heart from "../../icons/heart.svg";
import share from "../../icons/share.svg";
import { districtOptions, settlementOptions } from "../../res/options";
import thousandSeparator from "../../utils/thousandSeparator";

function Info({ property }) {
  const district = districtOptions.find(
    (option) => option.value === property.district
  );
  const settlement = settlementOptions.find(
    (option) => option.value === property.settlement
  );

  return (
    <div className="rounded-2xl bg-white py-8 px-6 card-shadow">
      <p className="card-heading">
        {settlement?.label} {district?.label}
        <br /> {property.street}
      </p>
      <p className="text-orange my-2 font-bold">{property.title}</p>
      <div className="mt-6">
        <div className="border-b pb-1">
          <span className="inline-flex gap-3">
            <img src={roomIcon} />
            <span className="text-lightgrey text-md font-bold">Szoba</span>
          </span>
          <span className="float-right font-bold">{property.rooms}</span>
        </div>
        <div className="border-b mt-3 pb-1">
          <span className="inline-flex gap-3">
            <img src={arrows} />
            <span className="text-lightgrey text-md font-bold">
              Alapterület
            </span>
          </span>
          <span className="float-right font-bold">
            {property.area}m<sup>2</sup>
          </span>
        </div>
      </div>
      <div className="mt-4 text-2xl text-blue font-bold">
        {thousandSeparator(property.price)} Ft
      </div>
      <div className="my-6">
        <button className="orange-button w-full" style={{ width: "100%" }}>
          Érdekel az ingatlan
        </button>
      </div>
      <div className="mt-2 grid grid-cols-2 text-lightgrey font-bold text-md">
        <div>
          <span className="inline-flex gap-2">
            <img src={heart} />
            <span className="mt-1">Kedvencekhez ad</span>
          </span>
        </div>
        <div>
          <span className="inline-flex gap-2 float-right">
            <img src={share} />
            <span className="mt-1">Megosztás</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Info;
