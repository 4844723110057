import React from "react";
import whiteLogo from "../icons/white-logo.svg";
import insta from "../icons/insta.svg";
import yt from "../icons/yt.svg";
import fb from "../icons/fb.svg";

const social = [yt, fb, insta];

const stripLinks = [
  "Adatkezelési tájékoztató",
  "Impresszum",
  "Jogi nyilatjozat",
  "Cookie-k használata",
];

function Footer({}) {
  return (
    <>
      <div className="footer-section py-8 footer-links-container text-white">
        <div>
          <p className="font-bold my-4">Kapcsolat</p>
          <p className="my-1">+36 30 090 0000</p>
          <p className="my-1">info@otthonzona.com</p>
          <p className="my-1">munkanapokon 10:00-17:00-ig</p>
        </div>
        <div>
          <p className="font-bold my-4">Kapcsolat</p>
          <p className="my-1">Vétel</p>
          <p className="my-1">Bérlés</p>
          <p className="my-1">Új ingatlanok</p>
        </div>
        <div>
          <p className="font-bold my-4">Eladnék</p>
          <p className="my-1">Eladás</p>
          <p className="my-1">Kiadás</p>
        </div>
        <div>
          <p className="font-bold my-4">Rólunk</p>
          <p className="my-1">GYIK</p>
          <p className="my-1">Állásajánlatok</p>
          <p className="my-1">Magunkról</p>
          <p className="my-1">Hírek, elemzések</p>
        </div>
        <div className="-mt-4">
          <img src={whiteLogo} className="h-40 w-40" />
          <div className="flex gap-2">
            {social.map((src) => (
              <img src={src} />
            ))}
          </div>
        </div>
      </div>
      <div className="footer-section footer-orange-strip flex justify-center items-center gap-6 py-6">
        {stripLinks.map((s) => (
          <span className="text-white">{s}</span>
        ))}
      </div>
    </>
  );
}

export default Footer;
