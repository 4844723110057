import React from "react";
import agentImage from "../../pictures/person.png";
import stars from "../../icons/stars.svg";
import whiteLogo from "../../icons/white-logo.svg";
import envl from "../../icons/env.svg";
import phone from "../../icons/phone.svg";
import { profilePhotoPlaceholder } from "../Profile";
import thousandSeparator from "../../utils/thousandSeparator";

function Agent ({ agent }) {

    return (
        <div className="rounded-2xl bg-white pt-8 card-shadow bg-blue overflow-hidden">
            <div className="justify-center flex-col flex items-center text-white">
                <div className="w-36 h-36 rounded-full overflow-hidden">
                    <img 
                        src={agent?.photo || profilePhotoPlaceholder}
                        className="min-h-full min-w-full" 
                    />
                </div>
                <p className="text-white font-bold text-2xl mt-4">{agent.firstName} {agent.lastName}</p>
                <p className="text-white text-sm font-bold">Ingatlanközvetítő</p>
                <div className="mt-4">
                    <img src={stars} />
                </div>
                <p className="mt-4 text-xs text-center">Érdekel az ingatlan?<br/> Lépj kapcsolatba az ingatlanközvetítővel</p>
            </div>
            <div className="orange-gradient p-8 rounded-t-[60px] mt-6 justify-center flex-col flex items-center text-white">
                <img src={whiteLogo} className="w-2/5" />
                <a href={`mailto:${agent?.email}`} className="block w-full">
                <button className="relative blue-button w-full mt-8 flex gap-12 items-center text-blue" style={{ width: "100%", background: "white" }}>
                    <img src={envl} className="ml-4"/>
                    <span className="text-blue">Üzenetet küldök</span>
                </button>
                </a>
                <button className="relative blue-button w-full mt-3 flex gap-12 items-center" style={{ width: "100%" }}>
                    <img src={phone} className="ml-4"/>
                    <span>+{thousandSeparator(agent?.phone)}</span>
                </button>
            </div>
        </div>
    )

}

export default Agent;
