import React from "react";
import ExpandableCard from "../Elements/ExpandableCard";
import icon from "../../pictures/app/ad-location.svg";
import AppSelect from "../Elements/AppSelect";
import Input from "../Elements/Input";
import Switch from "../Elements/Switch";
import { districtOptions, settlementOptions } from "../../res/options";

function Location ({ prefill }) {

    return (
        <ExpandableCard 
            Component={({ isExpanded, setIsExpanded }) => {

                return (
                    <div className="w-full my-8 p-6">
                        <div className="w-full grid grid-cols-2">
                            <div className="flex gap-4 items-center">
                            <img src={icon} className="h-6 w-6" />
                            <span className="text-xl font-bold mt-0">
                            Elhelyezkedés
                            </span>
                            </div>
                            <div>
                                {
                                    !isExpanded &&
                            <span className="float-right">
                                <button 
                                    type="button" 
                                    className="orange-button"
                                    onClick={() => setIsExpanded(!isExpanded)}
                                >
                                    Beállítás
                                </button>
                            </span>
                                }
                            </div>
                        </div>
                        {
                            <div className="py-6" style={{ display: isExpanded ? "block" : "none" }}>
                                <div className="grid grid-cols-3 gap-4">
                                    <div>
                                        <p className="font-bold">Település</p>
                                        <AppSelect 
                                            options={settlementOptions} 
                                            name="settlement"
                                            prefill={prefill}
                                        />
                                    </div>
                                    <div>
                                        <p className="font-bold">Városrész</p>
                                        <AppSelect 
                                            options={districtOptions} 
                                            name="district"
                                            prefill={prefill}
                                        />
                                    </div>
                                    <div>
                                        <p className="font-bold">Utca</p>
                                        <div className="mt-2">
                                        <Input 
                                            variant="white"
                                            name="street"
                                            prefill={prefill}
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-3 gap-4 my-4">
                                    <div>
                                        <p className="font-bold">Házszám</p>
                                        <Input 
                                            variant="white"
                                            name="houseNo"
                                            prefill={prefill}
                                        />
                                    </div>
                                    <div className="flex">
                                        <div>
                                            <p className="font-bold">Cím pontossága</p>
                                            <p className="text-gray-500 text-sm font-medium">
                                            Szeretnéd, hogy az ingatlan a találati listában pontos címmel jelenjen meg?
                                            </p>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <Switch name="exactPosition" defaultValue={prefill?.["exactPosition"]}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-4 h-1">
                                    <span className="float-right">
                                        <button type="button" className="blue-button" onClick={() => setIsExpanded(!isExpanded)}>Mentés</button>
                                    </span>
                                </div>
                            </div>  
                        }
                    </div>
                );

            }}
        />
    )

}

export default Location;
