import React, { useContext, useEffect, useMemo, useState } from "react";
import Layout from "../Elements/AppLayout";
import { AppContext } from "../../App";
import Loading from "../Elements/Loading";
import { request } from "../../utils/request";
import moment from "moment";
import Input from "../Elements/Input";

const ordinals = ["st", "nd", "rd", "th"];

function BiddingPropertyCard ({ property, properties, setProperties }) {

    const context = useContext(AppContext);

    const [data, setData] = useState();
    const [loading, setLoading] = useState();
    const [credit, setCredit] = useState();
    const [bidding, setBidding] = useState(false);

    const { position, page, currentPosition, isCurrentHighest, max, currentCredit } = useMemo(() => {
        const isPromotionActive = property.bidExpirationTime && ((new Date(property.bidExpirationTime)).getTime() > Date.now())
        const currentCredit = isPromotionActive ? (property.credit || 0) : 0;
        const creditInt = parseInt(credit + currentCredit);
        let position = 1;
        data?.forEach(item => {
            if (parseInt(item.price) >= creditInt) {
                position ++;
            }
        });
        return {
            position,
            page: Math.ceil(position/4),
            max: Math.max(...(data?.map(item => item.price) || [])),
            highestId: data?.[0]?.id,
            isCurrentHighest: property?.id === data?.[0]?.id,
            currentCredit,
        };
    }, [property, credit, data]);

    useEffect(() => {
        setLoading(true);
        request("/api/property/bid-prices?district=" + property.district)
        .then(data => {
            setData(data.data);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
        })
    }, [property?.id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setBidding(true);
            const response = await request(`/api/property/upgrade/${property.id}`, {
                method: "POST",
                body: JSON.stringify({
                    bidCredits: parseInt(credit),
                }),
            });
            const { property: updatedProperty, user } = response;
            if (updatedProperty) {
                e.target.reset();
                for (let x in updatedProperty) {
                    property[x] = updatedProperty[x];
                }
                context.setData({ ...context.data, user });
                setProperties([ ...properties ]);

                request("/api/property/bid-prices?district=" + property.district)
                .then(data => {
                    setData(data.data);
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                })

            }
        }
        catch (err) {}
        finally {
            setBidding(false);
        }
    }

    if (loading) {
        return (
            <div className="flex h-24 justify-center items-center">
                <Loading />
            </div>
        )
    }

    if (data) {
        return (
            <div className="bg-white p-8">
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-4 gap-4">
                        <div className="col-span-3">
                            <Input 
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    setCredit(e.target.value);
                                }}
                                placeholder="Bidding Price"
                                type="number"
                            />
                        </div>
                        <div className="">
                            <button className="blue-button">
                                {
                                    bidding ?
                                    <Loading /> :
                                    "Bid"
                                }
                            </button>
                        </div>
                    </div>
                </form>
                <div className="my-2">
                    <p className="font-bold">Bidding Positions</p>
                    {
                        data?.map((item, index) => {
                            const isCurrentHighest = item.id === property.id;
                            return (
                                <div className="flex gap-2">
                                    <p>{index + 1} {ordinals[index % 10]} position has spent:</p>
                                    <p className="font-bold">{item.price} credits</p>
                                    {
                                        isCurrentHighest &&
                                        <p className="font-bold">(Current Ad)</p>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                {
                credit ?
                <div className="my-2">
                    If you bid using {credit} credits, your ad will be on page {page} at {(position%4)} position
                </div> : (
                    isCurrentHighest ? null :
                    <div className="my-2">
                        To be on 1<sup>st</sup> page and 1<sup>st</sup> position, spend more than {max + 1} credits
                    </div>
                )
                }
            </div>
        )
    }
}

function BiddingDashboard () {

    const { user } = useContext(AppContext);
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showDetailsId, setShowDetailsId] = useState();

    useEffect(() => {
        request("/api/user/my-properties")
        .then(data => {
            setLoading(false);
            setProperties(data.data);
        })
        .catch(err => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <Layout>
                <div className="w-full h-96 flex justify-center items-center">
                    <Loading />
                </div>
            </Layout>
        )
    }

    return (
        <Layout selected={0}>
        <div className="p-8">
            <p className="text-xl font-semibold">
                Bidding
                <span className="float-right !text-lg font-bold text-gray-600">
                    {user?.credit} hitel
                </span>
            </p>
            <div className="my-8">
                {
                    properties.map(property => {
                        return (
                            <div className={`w-full mt-2 bg-white rounded-lg shadow-lg my-4 overflow-hidden`}>
                                <div className="grid grid-cols-4 h-48 overflow-hidden">
                                    <div className="overflow-hidden">
                                        <img src={property.photos[0]} className="min-h-full min-w-full" />
                                    </div>
                                    <div className="col-span-3 p-4">
                                        <p className="font-semibold text-lg">{property.title}</p>
                                        <p className="my-2">{property.settlement} {property.district}</p>
                                        <p className="my-2 font-bold text-gray-600">
                                            {(property.price/1e6).toFixed(2)} M Ft
                                        </p>
                                        <p className="my-1">
                                            {
                                                property.bidExpirationTime ? (
                                                new Date(property.bidExpirationTime).getTime() > Date.now() ? 
                                                    <p>
                                                        Subscription ending on {moment(new Date(property.bidExpirationTime)).format("DD MMM YYYY hh:mm a")}
                                                        <br />
                                                        You spent {property.credit} credits
                                                    </p>
                                                : <p>
                                                        Promotion expired on {moment(new Date(property.bidExpirationTime)).format("DD MMM YYYY hh:mm a")}
                                                        <br />
                                                        You spent {property.credit} credits
                                                    </p>
                                                ) : null
                                            }
                                        </p>
                                        <p className="my-1">
                                            <span 
                                                onClick={() => {
                                                    setShowDetailsId(property.id === showDetailsId ? undefined : property.id);
                                                }} 
                                                className="text-blue-600 cursor-pointer"
                                            >
                                                {
                                                    property.id === showDetailsId ? "Hide" : "Show"
                                                } bidding details
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                {
                                    showDetailsId === property.id &&
                                    <BiddingPropertyCard 
                                        property={property}
                                        properties={properties}
                                        setProperties={setProperties}
                                        key={property.credit} 
                                    />
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
        </Layout>
    )
}

export default BiddingDashboard;
