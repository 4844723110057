import React, { useState } from "react";
import Layout from "../Elements/AppLayout";
import BasicDetails from "./BasicDetails";
import Location from "./Location";
import MoreDetails from "./MoreDetails";
import UploadImages from "./UploadImages";
import convertFormDataToJson from "../../utils/fd";
import { toast } from "react-hot-toast";
import { request } from "../../utils/request";
import isLoggedIn from "../../utils/isLoggedIn";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Link } from "react-router-dom";

function PostAd({ prefill }) {
  const loggedIn = isLoggedIn();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = convertFormDataToJson(e.target);

    // transformation
    body.exactPosition = body.exactPosition === "true";
    body.photos = body.photos.split(",");
    body.rooms = parseInt(body.rooms || 0);
    body.area = parseInt(body.area || 0);

    // validations

    if (!body.title) {
      return toast.error("A cím kötelező");
    }

    if (!body.price) {
      return toast.error("Az ár kötelező");
    }
    body.price = parseInt(body.price);

    if (!body.adType) {
      return toast.error("Válasszon egy hirdetéstípust");
    }
    if (!body.propertyType) {
      return toast.error("Válassza ki az ingatlan típusát");
    }
    if (!body.description) {
      return toast.error("Leírás nincs megadva");
    }

    if (!body.district || !body.settlement || !body.street) {
      return toast.error("Kérjük település, kerület és utca megadását");
    }

    setIsLoading(true);
    if (prefill) {
      request(`/api/property/${prefill.id}`, {
        body: JSON.stringify(body),
        method: "PUT",
      })
        .then((res) => {
          setTimeout(() => {
            window.location.href = `/property/${res?.data?.id}`;
          }, 300);
          toast.success("A tulajdon sikeresen szerkesztve");
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
    else {
    request("/api/property", {
      body: JSON.stringify(body),
      method: "POST",
    })
      .then((res) => {
        setTimeout(() => {
          window.location.href = `/property/${res?.data?.id}`;
        }, 300);
        toast.success("Feladott hirdetés");
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
    }

    console.log(body);
  };

  if (!loggedIn) {
    return (
      <div className="w-full">
        <Navbar />
        <div className="h-96 flex justify-center items-center flex-col gap-4">
          <span className="font-medium text-gray-600 text-lg">
            Hirdetés feladásához jelentkezzen be, vagy hozzon létre fiókot
          </span>
          <span className="flex gap-3">
            <Link to="/login">
              <button className="blue-button">Bejelentkezés</button>
            </Link>
            <Link to="/register">
              <button className="orange-button">Regisztráció</button>
            </Link>
          </span>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <Layout selected={1}>
      <div className="p-8 w-full">
        <p className="font-bold text-xl">Hirdetés feladás</p>
        <form onSubmit={handleSubmit}>
          <BasicDetails prefill={prefill} />
          <Location prefill={prefill} />
          <MoreDetails prefill={prefill} />
          <UploadImages prefill={prefill} />
          <div>
            <span className="float-right mb-8">
              <button className="blue-button">
                {isLoading ? "..." : "Feltöltés"}
              </button>
            </span>
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default PostAd;
