import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Input from "../Elements/Input";
import loginImage from "../../pictures/login.png";
import { Link } from "react-router-dom";
import { request } from "../../utils/request";
import convertFormDataToJson from "../../utils/fd";
import Cookies from "js-cookie";

function Login() {
  const [emailNotVerified, setEmailNotVerified] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const json = convertFormDataToJson(e.target);

    let apiEndpoint = forgotPassword
      ? "/api/auth/forgot-password"
      : "/api/auth/login";

    console.log("API endpoint:", apiEndpoint);
    console.log("Form data:", json);

    request(apiEndpoint, {
      body: JSON.stringify(json),
      method: "POST",
    }).then((data) => {
      console.log("Data received:", data);
      if (data.error) {
        if (data.error === "Email not verified") setEmailNotVerified(true);
        else if (data.error === "Incorrect email or password")
          setWrongCredentials(true);
        else if (data.error === "User with this email does not exist")
          setWrongCredentials(true);
      } else {
        if (!forgotPassword) {
          Cookies.set("token", data.data.token);
          window.location = "/post-ad";
        } else {
          setEmailSent(true);
          console.log("Email sent state:", emailSent);
        }
      }
    });
  };

  return (
    <div>
      <Navbar />
      <div className="grid grid-cols-2 bg-gray-50">
        <div>
          <img className="min-w-full min-h-full" src={loginImage} />
        </div>
        <div className="p-16 flex justify-center items-center bg-[rgb(247,247,247)]">
          <div>
            <h1 className="text-4xl font-bold my-8">
              <span className="text-orange">Üdv újra itt! </span>
              <br />
              Jelentkezz be a fiókodba
            </h1>
            <p className="text-lightgrey text-lg w-2/3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam odio
              quam, bibendum sit
            </p>
            <form onSubmit={handleSubmit}>
              <div className="my-8 w-2/3">
                <p className="font-bold my-2">E-mail cím</p>
                {emailNotVerified && (
                  <div className="alert-box">Email cím nincs megerősítve!</div>
                )}
                {wrongCredentials && (
                  <div className="alert-box">Hibás email cím vagy jelszó!</div>
                )}
                {emailSent && (
                  <div className="alert-box-blue">
                    Jelszó helyrteállító link elküldve az email címedre!
                  </div>
                )}
                <Input
                  placeholder="Írd be az e-mail címed"
                  name="email"
                  type="email"
                  required
                />
              </div>
              {!forgotPassword && (
                <div className="mb-4 w-2/3">
                  <p className="font-bold my-2">Jelszó</p>
                  <Input
                    placeholder="Írd be a jelszavad"
                    name="password"
                    type="password"
                    required
                  />
                </div>
              )}
              <div className="w-2/3">
                <span
                  className="float-right text-sm font-medium text-gray-600 cursor-pointer"
                  onClick={() => setForgotPassword(!forgotPassword)}
                  style={{ cursor: "pointer" }}
                >
                  {forgotPassword
                    ? "Bejelentkezés"
                    : "Elfelejtettem a jelszavam"}
                </span>
              </div>
              <div className="w-2/3 mt-16">
                <button type="submit" className="orange-button !w-full">
                  {forgotPassword ? "Jelszó helyreállítása" : "Bejelentkezés"}
                </button>
              </div>
            </form>
            <div className="bg-gray-300 w-full h-[1px] w-2/3 my-8" />
            <Link to="/register">
              <button className="blue-button !w-2/3">
                Még nincs fiókod? Hozz létre egyet!
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
