import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import newsCover from "../../pictures/news-cover.png";
import news1 from "../../pictures/newsp-1.png";
import news2 from "../../pictures/newsp-2.png";
import news3 from "../../pictures/newsp-3.png";
import news4 from "../../pictures/newsp-4.png";
import news5 from "../../pictures/newsp-5.png";
import news6 from "../../pictures/newsp-6.png";
import NewsCard from "../Elements/NewsCard";
import TopArticles from "./TopArticles";
import Categories from "./Categories";

const newsData = [
    {
        image: news1,
        heading: "Családi házra hajtanak a fiatalok",
        text: "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Proin congue pretium erat, eget ultrices urna ultrices"
    },
    {
        image: news2,
        heading: "Vége az extrém drágulásnak, alig növekedtek a négyzetméterárak",
        text: "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Proin congue pretium erat, eget ultrices urna ultrices"
    },
    {
        image: news3,
        heading: "Családi házra hajtanak a fiatalok",
        text: "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Proin congue pretium erat, eget ultrices urna ultrices"
    },
    {
        image: news4,
        heading: "Családi házra hajtanak a fiatalok",
        text: "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Proin congue pretium erat, eget ultrices urna ultrices"
    },
    {
        image: news5,
        heading: "Vége az extrém drágulásnak, alig növekedtek a négyzetméterárak",
        text: "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Proin congue pretium erat, eget ultrices urna ultrices"
    },
    {
        image: news6,
        heading: "Családi házra hajtanak a fiatalok",
        text: "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Proin congue pretium erat, eget ultrices urna ultrices"
    },
]

function NewsPage () {
    return (
        <div className="w-full">
            <Navbar />
            <div className="w-full grid grid-cols-3">
                <div className="p-4">
                    <div className="w-full">
                        <img 
                            src={newsCover}
                            className="w-full rounded-lg"
                        />
                    </div>
                    <div className="w-full my-4">
                        <TopArticles />
                    </div>
                    <div className="w-full my-4">
                        <Categories />
                    </div>
                </div>
                <div className="col-span-2 ">
                    <p className="text-2xl font-bold ml-6">
                        Hírek
                    </p>
                    <div className="grid grid-cols-2 gap-3  p-12 pt-6 pl-6">
                    {
                        newsData.map(news => (
                            <NewsCard 
                                news={news}
                            />
                        ))
                    }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NewsPage;
