import React, { useMemo, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import cover from "../pictures/search-cover.png";
import SearchBar from "../components/Elements/SearchBar";
import p1 from "../pictures/p1.png";
import p2 from "../pictures/p2.png";
import p3 from "../pictures/p3.png";
import PropertyCard from "../components/Elements/PropertyCard";
import { request } from "../utils/request";
import objectToQueryString from "../utils/objectToQuery";

const properties = [
    { image: p1, title:"Eladó újépítésű lakás", },
    { image: p2, title:"Eladó Családi ház", },
    { image: p3, title:"Kiadó Panel lakás", },
    { image: p1, title:"Eladó újépítésű lakás", },
    { image: p2, title:"Eladó Családi ház", },
    { image: p3, title:"Kiadó Panel lakás", },
]

let abortController = new AbortController();

function Search () {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const handleOnChangeFilter = async (filters) => {
        try {
            abortController?.abort();
            abortController = new AbortController();
            setLoading(true);
            let signal = abortController.signal;
            const ads = await request("/api/property?" + objectToQueryString(filters), { signal });
            setData(ads.data);
        }
        catch(err){}
        finally {
            setLoading(false);
        }
    }

    const properties = useMemo(() => {
        console.log(data[0]);
        return data.map(d => ({ ...d, image: d.photos[0] }))
    }, [data]);

    return (
        <>
            <Navbar />
            <div>
                <img src={cover} className="w-full"/>
            </div>
            <div className="w-10/12 mx-auto py-8 -mt-32">
                <SearchBar 
                    onChangeFilters={handleOnChangeFilter}
                />
            </div>
            <div className="w-10/12 mx-auto">
                <p className="text-2xl font-bold">
                    <span className="text-blue">Eladó lakás - </span>
                    Budapest II. kerület
                </p>
            </div>
            {
                loading ?
                <div className="flex justify-center items-center h-96">
                    Loading ...
                </div> :
                properties.length === 0 ?
                <div className="flex justify-center items-center h-96">
                    <span className="text-gray-700 font-bold">Nem található tulajdonság</span>
                </div>
                : <div className="w-10/12 mx-auto py-2 mb-12 my-4 grid grid-cols-3 gap-4">
                    {
                        properties.map(property => <PropertyCard property={property} />)
                    }
                </div>
            }
            <Footer />
        </>
    )

}

export default Search;
