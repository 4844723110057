import { useEffect, useState } from "react";
import Select from "react-select";
import chevrondown from "../../icons/chevrondown.svg";

function AppSelect ({ options, name, prefill, placeholder, onChange, }) {

    const [value, setValue] = useState(
        options.find(option => (option.value === prefill?.[name]))
    );

    useEffect(() => {
        console.log(">>>", name, options, prefill, options.find(option => (option.value === prefill?.[name])));
    }, [prefill]);

    return (
        <div className="mt-2 border-2 rounded-full p-0.5">
            <input 
                name={name}  
                value={value?.value}
                type="hidden"
            />
        <Select 
            options={options}
            onChange={(value) => {
                setValue(value);
                onChange?.(value);
            }}
            value={value}
            styles={{
                control: (provided) => ({
                    ...provided,
                    background: 'transparent',
                    border: 0,
                    fontSize: 16,
                    minHeight: 20,
                    fontWeight: "bold",
                    boxShadow: 'none',
                  }),
                }}
                placeholder={placeholder || "Kezdj el gépelni a kereséshez"}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => (
                    <div style={{ marginTop: 0 }}>
                        <span className={"box-border h-full py-2.5 h-full inline-block w-[50px] orange-gradient rounded-full flex justify-center items-center "}>
                            <img 
                                src={chevrondown}
                                className="h-4 w-4"
                            />
                        </span>
                    </div>
                  ),
                }}
        />
        </div>
    )
}

export default AppSelect;
