import Cookies from "js-cookie";

export const api = /localhost/.test(window.location)
  ? "http://localhost:5000"
  : "https://otthonzona.com";

const toQuery = (query) => {
  let queryStr = "";
  for (let x in query) {
    queryStr += `${x}=${query[x]}&`;
  }
  return queryStr.slice(0, -1) ? "?" + queryStr.slice(0, -1) : "";
};

export const request = async (
  route,
  { params = "", body = "", query = {}, method = "GET", signal } = {}
) => {
  try {
    const res = await fetch(api + route + params + toQuery(query), {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${Cookies.get("token")}`,
      },
      method,
      body: method == "GET" ? null : body,
      signal,
    });
    return res.json();
  } catch (err) {
    throw err;
  }
};
