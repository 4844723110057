import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Info from "../components/Property/Info";
import Agent from "../components/Property/Agent";
import Photos from "../components/Property/Photos";
import About from "../components/Property/About";
import Map from "../components/Property/Map";
import { useParams } from "react-router-dom";
import { request } from "../utils/request";

function Property() {
  const { id } = useParams();
  const [property, setProperty] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      request(`/api/property/${id}`)
        .then((data) => {
          setProperty(data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log("ERROR", err);
          setLoading(false);
        });
    }
  }, [id]);

  if (property)
    return (
      <>
        <Navbar />
        <div className="orange-gradient relative rounded-br-3xl rounded-bl-3xl h-44 -mb-24 z-0" />
        <div className="grid grid-cols-3 gap-10 w-10/12 mx-auto relative z-40 mb-12">
          <div>
            <Info property={property} />
            <div className="mt-4">
              <Agent agent={property?.agent} />
            </div>
          </div>
          <div className="col-span-2">
            <Photos property={property} />
            <About property={property} />
            <div className="mt-6">
              <Map />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
}

export default Property;
