import React, { useState } from "react"; // Add useState
import Navbar from "../Navbar";
import Footer from "../Footer";
import Input from "../Elements/Input";
import signupImage from "../../pictures/signup.png";
import { Link } from "react-router-dom";
import { request } from "../../utils/request";
import convertFormDataToJson from "../../utils/fd";

function SignUp() {
  const [emailSent, setEmailSent] = useState(false); // New state
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const json = convertFormDataToJson(e.target);
    request("/api/auth/register", {
      body: JSON.stringify(json),
      method: "POST",
    }).then((data) => {
      if (data.error) {
        return alert(data.error);
      }
      setEmailSent(true); // Set state
      // window.location = "/login"; // Don't redirect yet
    });
  };

  return (
    <div>
      <Navbar />
      <div className="grid grid-cols-2 bg-gray-50">
        <div>
          <img className="min-w-full min-h-full" src={signupImage} />
        </div>
        <div className="p-16 flex justify-center items-center bg-[rgb(247,247,247)]">
          <div>
            {emailSent ? (
              <h1>
                Kérjük, erősítsd meg az email címedet a visszaigazoló emailben
                található linken keresztül.
              </h1>
            ) : (
              <>
                <h1 className="text-4xl font-bold my-8">
                  <span className="text-orange">Otthon Zóna</span>
                  <br />
                  Profil létrehozása
                </h1>
                <p className="text-lightgrey text-lg w-2/3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                  odio quam, bibendum sit
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-2 gap-2 w-2/3">
                    <div className="mt-8">
                      <p className="font-bold my-2">Vezetéknév</p>
                      <Input
                        placeholder="Írd be a vezetékneved"
                        name="lastName"
                        type="text"
                        required
                      />
                    </div>
                    <div className="mt-8">
                      <p className="font-bold my-2">Keresztnév</p>
                      <Input
                        placeholder="Írd be a keresztneved"
                        name="firstName"
                        type="text"
                        required
                      />
                    </div>
                  </div>
                  <div className="my-2 mt-4 w-2/3">
                    <p className="font-bold my-2">E-mail cím</p>
                    <Input
                      placeholder="Írd be az e-mail címed"
                      name="email"
                      type="email"
                      required
                    />
                  </div>
                  <div className="mb-4 mt-4 w-2/3">
                    <p className="font-bold my-2">Telefon</p>
                    <Input
                      placeholder="Írd be a telefonszámod"
                      name="phone"
                      type="text"
                      required
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-2 w-2/3">
                    <div className="mb-4">
                      <p className="font-bold my-2">Jelszó</p>
                      <Input
                        placeholder="Írd be a jelszavad"
                        name="password"
                        type="password"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <p className="font-bold my-2">Jelszó újra</p>
                      <Input
                        placeholder="Írd be a jelszavad"
                        name="confirmPassword"
                        type="password"
                        required
                      />
                    </div>
                  </div>
                  <div className="w-2/3 mt-8">
                    <button type="submit" className="orange-button !w-full">
                      Regisztráció
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SignUp;
