import React from "react";

const articles = [
    { text: "Családi házra hajtanak a fiatalok" },
    { text: "Vége az extrém drágulásnak, alig növekedtek a négyzetméterárak" },
    { text: "Új lakópark épül Budán" }
]

function TopArticles () {
    
    return (
        <div className="card p-4 !rounded-2xl">
            <p className="card-heading">Top cikkek</p>
            {
                articles.map((article, index) => (
                    <div className="grid gap-3 my-2" style={{ gridTemplateColumns: "20px 1fr" }}>
                        <div className="flex items-center text-2xl font-bold">
                            <span className="text-orange">{index + 1}</span>
                        </div>
                        <div>
                            <p className="text-sm text-gray-900 font-bold">{article.text}</p>
                            <span className="text-blue text-xs font-medium">2023.07.09.</span>
                        </div>
                    </div>
                ))
            }
        </div>
    )

}

export default TopArticles;
