import React, { useEffect, useState } from "react";
import Layout from "../Elements/AppLayout";
import Loading from "../Elements/Loading";
import { request } from "../../utils/request";
import MyPropertyCard from "./MyPropertyCard";

function ManageAds ({}) {

    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        request("/api/user/my-properties")
        .then(data => {
            setLoading(false);
            setProperties(data.data);
        })
        .catch(err => {
            setLoading(false);
        });
    }, []);

    return (
        <Layout selected={0}>
            <div className="p-8">
                <p className="card-heading">Hirdetések kezelése</p>
                {
                    loading ?
                    <div className="flex justify-center h-48 items-center my-8 gap-4 font-medium text-gray-900">
                        <Loading />
                        <span>A hirdetések betöltése folyamatban van</span>
                    </div> :
                    <div>
                        {
                            properties.map(property => (
                                <MyPropertyCard property={property} />
                            ))
                        }
                    </div>
                }
            </div>
        </Layout>
    )
}

export default ManageAds;
